import { atom, selector } from 'recoil';
import type { Place } from './types';

export const placesAtom = atom<Place[]>({
  key: 'placesAtom',
  default: [],
});

export const hasPlacesSelector = selector({
  key: 'hasPlacesSelector',
  get: ({ get }) => {
    const places = get(placesAtom);
    return places.length > 0;
  },
});

/**
 * 停車地点の緯度経度を返す
 */
export const placesLatLngsSelector = selector({
  key: 'placesLatLngSelector',
  get: ({ get }) => {
    const places = get(placesAtom);
    return places.map((place) => place.location);
  },
});
