import { useSetRecoilState } from 'recoil';
import type { Token } from '@tier4/webauto-auth-core';
import AuthenticationManager from './authenticationManager';
import { isAuthErrorAtom } from '@/data/auth/user/states';
import { useCallback } from 'react';

export const useAuthToken = (): (() => Promise<Token>) => {
  const setIsAuthError = useSetRecoilState(isAuthErrorAtom);

  /**
   * 認可情報取得
   */
  const getToken = useCallback(async (): Promise<Token> => {
    const tokenSource = await AuthenticationManager.getTokenSource();
    const token = await tokenSource.getToken();
    if (!token) {
      setIsAuthError(true);
    }
    return token;
  }, [setIsAuthError]);

  return getToken;
};
