import { useCallback, useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { useAuthToken } from './authToken';
import { Failure, Success, type APIState, type Result } from '../types';
import type { Project } from '@/data/auth/project/types';
import { useAuthAPI } from '../hooks';
import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import { projectAtom } from '@/data/auth/project/states';
import { environmentAtom } from '@/data/fms/environment/states';
import { FEATURE_SCOPES, SCOPES } from '@/data/auth/scope/constants';
import { useNotification } from '@/data/notification/hooks';

type AssertRequestParam = {
  token: string;
  feature_scope?: string;
  scope?: string;
};

type AssertResponse = {
  result: boolean;
  scope: string;
  missing_scope: string;
  feature_scope: string;
  missing_feature_scope: string;
  client_application_scope: string;
};

type AssertResult = {
  feature_scope: string;
  scope: string;
};

export const usePostAssertAPI = (): {
  state: APIState;
  postAssertProjects: (projects: Project[]) => Promise<Project[]>;
  postAssert: () => Promise<AssertResult | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const getToken = useAuthToken();
  const { notifyError } = useNotification();
  const authAPI = useAuthAPI();

  const request = useCallback(
    async (
      param: AssertRequestParam,
    ): Promise<Result<AssertResponse, AxiosResponse>> => {
      try {
        const res = await authAPI.post('/assert', param);
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [authAPI],
  );

  /**
   *
   */
  const postAssertProjects = useCallback(
    async (projects: Project[]) => {
      setState('loading');
      const token = await getToken();
      if (!token) {
        throw new axios.Cancel('token is null');
      }
      const requestClientApplicationScopes = projects.map(
        (project) => `${project.id}:calls`,
      );

      const MAX_SCOPE_COUNT = 25;
      const requestCount = Math.ceil(
        requestClientApplicationScopes.length / MAX_SCOPE_COUNT,
      );
      const requestCountArr = [...Array(requestCount)].map((_, i) => i);
      let resClientApplicationScope = '';
      const splitRequest = async () => {
        for (const i of requestCountArr) {
          const filteredScopes = requestClientApplicationScopes.filter(
            (_, j) => i * MAX_SCOPE_COUNT <= j && j < (i + 1) * MAX_SCOPE_COUNT,
          );
          const paramsScope = filteredScopes.map((scope) => scope).join(' ');
          const requestBody = {
            token: token.accessToken,
            client_application_scope: paramsScope,
          };
          const res = await request(requestBody);
          if (!res || res.isFailure()) {
            setState('hasError');
            notifyError({
              message: `権限情報の取得に失敗しました: ${
                res.value?.data?.message ?? '原因不明'
              }`,
            });
          } else {
            resClientApplicationScope +=
              i === 0
                ? res.value.client_application_scope
                : ` ${res.value.client_application_scope}`;
          }
        }
      };
      await splitRequest();

      setState('hasValue');

      return projects.filter((project) =>
        resClientApplicationScope.includes(`${project.id}:calls`),
      );
    },
    [request, getToken, notifyError],
  );

  /**
   * 走行環境作成と、エリアマップバージョン情報取得以外の scope, feature_scope を検証
   */
  const postAssert = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        setState('loading');
        const token = await getToken();
        if (!token) {
          throw new axios.Cancel('token is null');
        }
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: '権限情報の取得に失敗しました',
          });
          setState('hasError');
          return null;
        }
        const requestFeatureScope = () => {
          const prefix = `${project.id}:fms`;
          return Object.values(FEATURE_SCOPES)
            .map((key) => `${prefix}:${key}`)
            .join(' ');
        };

        const MAX_SCOPE_COUNT = 25;
        const scopePrefix = `${project.id}:environment:${environment.environment_id}`;
        const scopeValues = Object.values(SCOPES);
        const scopeRequestCount = Math.ceil(
          scopeValues.length / MAX_SCOPE_COUNT,
        );
        const scopeRequestCountArr = [...Array(scopeRequestCount)].map(
          (_, i) => i,
        );
        let resFeatureScope = '';
        let resScope = '';
        const splitRequest = async () => {
          for (const i of scopeRequestCountArr) {
            const filteredScopes = scopeValues.filter(
              (_, j) =>
                i * MAX_SCOPE_COUNT <= j && j < (i + 1) * MAX_SCOPE_COUNT,
            );
            const paramsScope = filteredScopes
              .map((scope) => `${scopePrefix}=${scope}`)
              .join(' ');
            const requestBody = {
              token: token.accessToken,
              scope: paramsScope,
              feature_scope: i === 0 ? requestFeatureScope() : '',
            };
            const res = await request(requestBody);
            if (!res || res.isFailure()) {
              setState('hasError');
              notifyError({
                message: `権限情報の取得に失敗しました: ${
                  res.value?.data?.message ?? '原因不明'
                }`,
              });
            } else {
              if (i === 0) resFeatureScope = res.value.feature_scope;
              resScope += i === 0 ? res.value.scope : ` ${res.value.scope}`;
            }
          }
        };
        await splitRequest();

        setState('hasValue');

        return {
          scope: resScope,
          feature_scope: resFeatureScope,
        };
      },
    [getToken, request, notifyError],
  );

  return {
    state,
    postAssert,
    postAssertProjects,
  };
};
