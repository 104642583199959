import { projectAtom } from '@/data/auth/project/states';
import type { Environment } from '@/data/fms/environment/types';
import { useNotification } from '@/data/notification/hooks';
import { useCallback, useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { Failure, Success, type APIState, type Result } from '../types';
import { useFMSAPI } from '../hooks';
import type { AxiosError, AxiosResponse } from 'axios';

/**
 * 指定IDの走行環境情報取得API
 */
export const useGetEnvironmentAPI = (): {
  state: APIState;
  getEnvironment: (environmentId: string) => Promise<Environment | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
    ): Promise<Result<Environment, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getEnvironment = useRecoilCallback(
    ({ snapshot }) =>
      async (environmentId: string) => {
        const project = await snapshot.getPromise(projectAtom);
        if (!project) {
          notifyError({
            message: `指定された走行環境の取得に失敗しました`,
          });
          setState('hasError');
          return null;
        }
        setState('loading');
        const res = await request(project.id, environmentId);
        if (!res || res.isFailure()) {
          notifyError({
            message: `指定された走行環境の取得に失敗しました: ${
              res.value?.data?.message ?? '原因不明'
            }`,
          });
          setState('hasError');
          return null;
        }
        setState('hasValue');
        return res.value;
      },
    [notifyError, request],
  );

  return {
    state,
    getEnvironment,
  };
};

/**
 * プロジェクト内の走行環境一覧取得API
 */
export const useGetEnvironmentsAPI = (): {
  state: APIState;
  getEnvironments: (projectId: string) => Promise<Environment[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();

  const request = useCallback(
    async (
      projectId: string,
    ): Promise<Result<Environment[], AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(`/${projectId}/environments`);
        if (!Array.isArray(res?.data)) return new Success([]);
        // 昇順ソート
        res?.data.sort((a: Environment, b: Environment) => {
          if (a.environment_name < b.environment_name) return -1;
          return 1;
        });
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getEnvironments = useCallback(
    async (projectId: string) => {
      setState('loading');
      const res = await request(projectId);
      if (!res || res.isFailure()) {
        if (res.value?.status !== 404) {
          notifyError({
            message: `走行環境一覧の取得に失敗しました: ${
              res.value?.data?.message ?? '原因不明'
            }`,
          });
        }
        setState('hasError');
        return [];
      }
      setState('hasValue');
      return res.value;
    },
    [notifyError, request],
  );

  return {
    state,
    getEnvironments,
  };
};
