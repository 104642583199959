import type { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { useAuthToken } from './auth';
import { useMemo } from 'react';
import axios from 'axios';
import { useMount } from 'react-use';

export const useAuthAPI = (): AxiosInstance => {
  const getToken = useAuthToken();

  const authAPI = useMemo(
    () =>
      axios.create({
        baseURL: `https://${import.meta.env.VITE_AUTH_API_DOMAIN}/${
          import.meta.env.VITE_AUTH_API_VERSION
        }`,
        responseType: 'json',
      }),
    [],
  );

  useMount(() => {
    authAPI.interceptors.request.use(
      async (config) => {
        const token = await getToken();
        if (!token) {
          // 認可トークンが取得出来ない場合リクエストをキャンセルする
          throw new axios.Cancel('token is null');
        }
        config.headers = {
          ...config.headers,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.accessToken}`,
        } as AxiosRequestHeaders;
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );
  });

  return authAPI;
};

export const useFMSAPI = (): AxiosInstance => {
  const getToken = useAuthToken();

  const fmsAPI = useMemo(
    () =>
      axios.create({
        baseURL: `https://${import.meta.env.VITE_FMS_API_DOMAIN}/${
          import.meta.env.VITE_FMS_API_VERSION
        }/projects`,
        responseType: 'json',
      }),
    [],
  );

  useMount(() => {
    fmsAPI.interceptors.request.use(
      async (config) => {
        const token = await getToken();
        if (!token) {
          // 認可トークンが取得出来ない場合リクエストをキャンセルする
          throw new axios.Cancel('token is null');
        }
        config.headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.accessToken}`,
        } as AxiosRequestHeaders;
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );
  });

  return fmsAPI;
};
