import React from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { css, Global, ThemeProvider } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Routes from '@/routes';
import { theme } from '@/utils/theme';
import { HelmetProvider } from 'react-helmet-async';
import { CssBaseline } from '@mui/material';
import { Info } from '@mui/icons-material';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import DrivingImageSrc from '/assets/img/maps/vehicle_driving.png';
import ReadyImageSrc from '/assets/img/maps/vehicle_ready.png';
import AbnormalImageSrc from '/assets/img/maps/vehicle_abnormal.png';
import PauseImageSrc from '/assets/img/maps/vehicle_pause.png';
import 'swiper/css';

export const DrivingImage = new Image();
DrivingImage.src = DrivingImageSrc as unknown as string;
export const ReadyImage = new Image();
ReadyImage.src = ReadyImageSrc as unknown as string;
export const AbnormalImage = new Image();
AbnormalImage.src = AbnormalImageSrc as unknown as string;
export const PauseImage = new Image();
PauseImage.src = PauseImageSrc as unknown as string;

// Sentry
const dsn = import.meta.env.VITE_SENTRY_DSN;
const environment = import.meta.env.VITE_ENV_TYPE;

if (dsn && environment) {
  Sentry.init({ dsn, environment });
}

const App: React.FC = () => (
  <HelmetProvider>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Global
          styles={css`
            html,
            body,
            #app {
              height: 100%;
            }

            a {
              text-decoration: none;
            }
          `}
        />
        <SnackbarProvider
          data-id="snackbar"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          maxSnack={5}
          iconVariant={{
            error: (
              <Info fontSize="small" sx={{ p: 0, marginInlineEnd: '8px' }} />
            ),
          }}
        >
          <RecoilRoot>
            <BrowserRouter basename="/">
              <Routes />
            </BrowserRouter>
          </RecoilRoot>
        </SnackbarProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </HelmetProvider>
);

const domRoot = document.getElementById('app');

// 各サービスのステータス確認
const statusCheck = async () => {
  // 各サービスのステータスエンドポイント
  const statusEndpoints = {
    Account: `${import.meta.env.VITE_OIDC_PROVIDER_ENDPOINT}status`,
    Auth: `https://${import.meta.env.VITE_AUTH_API_DOMAIN}/status`,
    FMS: `https://${import.meta.env.VITE_FMS_API_DOMAIN}/status`,
  } as const;

  const statuses = await Promise.all(
    Object.entries(statusEndpoints).map(async ([key, value]) => {
      const res = await axios.get(value);
      console.log(
        `%c${key} status:`,
        `color: #2196f3`,
        res.data.status.toUpperCase(),
      );
      return res.data.status;
    }),
  );

  if (statuses.includes('active')) {
    // メンテナンス中の場合は 503 ページに遷移
    location.href = '/503.html';
    return;
  }

  // アプリレンダリング
  if (!domRoot) return;
  const root = createRoot(domRoot);
  root.render(<App />);
};

statusCheck();
