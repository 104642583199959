import { atom } from 'recoil';
import type { Environment, WebAutoApplicationEnvironmentOption } from './types';

export const environmentChangeLoadingAtom = atom({
  key: 'environmentChangeLoadingAtom',
  default: true,
});

export const environmentAtom = atom<Environment | null>({
  key: 'environmentState',
  default: null,
});

export const environmentsAtom = atom<Environment[]>({
  key: 'environmentsState',
  default: [],
});

export const environmentOptionAtom =
  atom<WebAutoApplicationEnvironmentOption | null>({
    key: 'environmentOptionAtom',
    default: null,
  });
