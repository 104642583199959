import type { ScheduleSetting, Settings } from './types';

const SCHEDULE_DEFAULT_SETTING: ScheduleSetting = {
  vehicleId: '',
  pickupDriveMode: 'auto',
} as const;

export const DEFAULT_SETTINGS: Settings = {
  schedule: SCHEDULE_DEFAULT_SETTING,
} as const;

export const KEY_NAME = 'settings' as const;
