import { atom } from 'recoil';
import type { AssertScope } from './types';

export const assertAtom = atom<AssertScope>({
  key: 'scopeAtom',
  default: {
    featureScope: '',
    scope: '',
    asserted: false,
  },
});
