import { atom } from 'recoil';
import type { Project } from './types';

export const projectAtom = atom<Project | null>({
  key: 'projectState',
  default: null,
});

export const projectsAtom = atom<Project[]>({
  key: 'projectsState',
  default: [],
});
