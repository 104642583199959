import {
  isConnected,
  isDisconnected,
  isError,
  isShutdown,
  isSuspending,
} from './utils';
import { atom, selector, selectorFamily } from 'recoil';
import store from 'store2';
import { environmentAtom } from '../environment/states';
import type { RemoteMonitorConfig, SelectedVehicleId, Vehicle } from './types';

export const selectedVehicleIdAtom = atom<SelectedVehicleId>({
  key: 'selectedVehicleIdAtom',
  default: store.local.get('vehicle_id'),
});

export const vehiclesAtom = atom<Vehicle[]>({
  key: 'vehiclesAtom',
  default: [],
});

export const remoteMonitorConfigAtom = atom<RemoteMonitorConfig>({
  key: 'remoteMonitorConfigAtom',
  default: {
    open: false,
    vehicle: null,
    monitorType: null,
  },
});

/**
 * 走行環境のすべての車両を返す
 */
export const allVehiclesSelector = selector({
  key: 'allVehiclesSelector',
  get: ({ get }) => {
    const environment = get(environmentAtom);
    const vehicles = get(vehiclesAtom);
    return vehicles.filter(
      (vehicle) => vehicle.environment_id === environment?.environment_id,
    );
  },
});

/**
 * 接続済みの車両一覧を返す
 * @param {Boolean} includeConnected
 */
export const connectedVehiclesSelector = selectorFamily({
  key: 'connectedVehiclesSelector',
  get:
    (includeConnected) =>
    ({ get }) => {
      const vehicles = get(allVehiclesSelector);
      if (includeConnected) {
        return vehicles.filter(
          (vehicle) => !isDisconnected(vehicle) && !isShutdown(vehicle),
        );
      }
      return vehicles.filter(
        (vehicle) =>
          !isDisconnected(vehicle) &&
          !isConnected(vehicle) &&
          !isShutdown(vehicle),
      );
    },
});

/**
 * 未接続の車両一覧を返す
 * disconnected || shutdown
 * @param {Boolean} includeConnected
 */
export const disconnectedVehiclesSelector = selectorFamily({
  key: 'disconnectedVehiclesSelector',
  get:
    (includeConnected) =>
    ({ get }) => {
      const vehicles = get(allVehiclesSelector);
      if (includeConnected) {
        return vehicles.filter(
          (vehicle) => isDisconnected(vehicle) || isShutdown(vehicle),
        );
      }
      return vehicles.filter(
        (vehicle) =>
          isDisconnected(vehicle) ||
          isConnected(vehicle) ||
          isShutdown(vehicle),
      );
    },
});

/**
 * allVehiclesSelectorからエラーの車両一覧を返す
 */
export const errorVehiclesSelector = selector({
  key: 'errorVehiclesSelector',
  get: ({ get }) => {
    const vehicles = get(allVehiclesSelector);
    return vehicles.filter((vehicle) => isError(vehicle));
  },
});

/**
 * connectedVehiclesSelectorから一時停止中の車両一覧を返す
 */
export const suspendingVehiclesSelector = selector({
  key: 'suspendingVehiclesSelector',
  get: ({ get }) => {
    const vehicles = get(connectedVehiclesSelector(false));
    return vehicles.filter((vehicle) => isSuspending(vehicle));
  },
});

/**
 * スケジュール登録可能な車両を返す
 */
export const scheduleRegistrableVehiclesSelector = selector({
  key: 'scheduleRegistrableVehiclesSelector',
  get: ({ get }) => {
    const vehicles = get(allVehiclesSelector);
    return vehicles.filter(
      (vehicle) =>
        !isDisconnected(vehicle) &&
        !isShutdown(vehicle) &&
        vehicle.acceptable_order !== 'none',
    );
  },
});

/**
 * allVehiclesSelectorから個別の車両情報を返す
 */
export const vehicleSelector = selectorFamily({
  key: 'vehicleSelector',
  get:
    (vehicleId: string | null | undefined) =>
    ({ get }) => {
      if (!vehicleId) return null;
      const vehicles = get(allVehiclesSelector);
      return vehicles.find((vehicle) => vehicle.vehicle_id === vehicleId);
    },
});
