import {
  ListItemButton,
  Avatar as MuiAvatar,
  Typography,
  type ListItemButtonProps,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import styled from '@emotion/styled';
import React from 'react';

type Props = ListItemButtonProps & {
  value: string;
};

const ProjectEnvironmentListItem: React.FC<Props> = ({
  value,
  ...rest
}: Props) => {
  return (
    <ListItemWrapper {...rest}>
      <Avatar>{value.charAt(0).toUpperCase()}</Avatar>
      <Typography style={{ fontSize: 14 }}>{value}</Typography>
    </ListItemWrapper>
  );
};

export default ProjectEnvironmentListItem;

const ListItemWrapper = styled(ListItemButton)`
  border: solid 1px ${grey[300]};
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Avatar = styled(MuiAvatar)`
  width: 26px;
  height: 26px;
  font-size: 16px;
  margin-right: 16px;
`;
