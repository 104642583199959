import { useMemo } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { environmentAtom, environmentsAtom } from './states';
import { projectAtom } from '@/data/auth/project/states';

export const useEnvironment = () => {
  const getEnvironmentName = useRecoilCallback(
    ({ snapshot }) =>
      async (environmentId: string) => {
        const einvironments = await snapshot.getPromise(environmentsAtom);
        const target = einvironments.find(
          (environment) => environment.environment_id === environmentId,
        );
        return target?.environment_name;
      },
    [],
  );

  return {
    getEnvironmentName,
  };
};

export const useRootPath = () => {
  const project = useRecoilValue(projectAtom);
  const environment = useRecoilValue(environmentAtom);

  const path = useMemo(
    () => `/${project?.id}/${environment?.environment_id}/`,
    [project, environment],
  );

  return path;
};
