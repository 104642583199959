import { List } from '@mui/material';
import styled from '@emotion/styled';

const ProjectEnvironmentList = styled(List)`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
`;

export default ProjectEnvironmentList;
