import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import type { NotifyOption } from './types';

export const useNotification = () => {
  const notify = useCallback((options: NotifyOption) => {
    enqueueSnackbar({
      ...options,
      message: (
        <div data-testid={options.testIdAttrs?.wrapperTestId}>
          {options.message}
        </div>
      ),
      action: (key) => (
        <IconButton
          data-key={key}
          size="small"
          color="inherit"
          onClick={() => {
            if (options.closeFunction) {
              options.closeFunction();
            }
            closeSnackbar(key);
          }}
          data-testid={options.testIdAttrs?.closeButtonTestId}
        >
          <Close fontSize="small" />
        </IconButton>
      ),
    });
  }, []);

  const notifyInfo = useCallback(
    (options: NotifyOption) => {
      notify({ ...options, variant: 'info' });
    },
    [notify],
  );

  const notifySuccess = useCallback(
    (options: NotifyOption) => {
      notify({ ...options, variant: 'success' });
    },
    [notify],
  );

  const notifyWarning = useCallback(
    (options: NotifyOption) => {
      notify({ ...options, variant: 'warning' });
    },
    [notify],
  );

  const notifyError = useCallback(
    (options: NotifyOption) => {
      notify({ ...options, variant: 'error' });
    },
    [notify],
  );

  return {
    notify,
    notifyInfo,
    notifySuccess,
    notifyWarning,
    notifyError,
  };
};
