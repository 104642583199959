import { Layout } from '@/components/common';
import ReactGA from 'react-ga4';
import { Box, CircularProgress, Icon, Paper } from '@mui/material';
import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useMount, usePrevious } from 'react-use';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import store from 'store2';
import styled from '@emotion/styled';
import ProjectEnvironmentDialog from '@/components/common/ProjectEnvrionmentDialog';
import { enableGA } from '@/utils/googleanalytics';
import { AuthenticationManager, useGetProjectsAPI } from '@/api/auth';
import {
  useGetEnvironmentsAPI,
  useGetPlacesAPI,
  useVehiclesAPI,
} from '@/api/fms';
import { usePostAssertAPI } from '@/api/auth/postAssert';
import { oidcErrorDescription } from '@/utils/oidc';
import SignInCallback from '@/pages/SigninCallback/SigninCallback';
import { AuthErrorDialog } from '@/components/common';
import { isAuthErrorAtom, userAtom } from '@/data/auth/user/states';
import { projectAtom, projectsAtom } from '@/data/auth/project/states';
import {
  environmentAtom,
  environmentChangeLoadingAtom,
  environmentsAtom,
} from '@/data/fms/environment/states';
import { selectedVehicleIdAtom, vehiclesAtom } from '@/data/fms/vehicle/states';
import { assertAtom } from '@/data/auth/scope/states';
import { placesAtom } from '@/data/fms/place/states';
import { useRootPath } from '@/data/fms/environment/hooks';
import { useVehiclesDataWebSocket } from '@/data/fms/vehicle/hooks';

const Map = lazy(() => import(/* viteChunkName: 'map' */ '@/pages/Map'));
const List = lazy(() => import(/* viteChunkName: 'list' */ '@/pages/List'));
const Settings = lazy(
  () => import(/* viteChunkName: 'settings' */ '@/pages/Settings'),
);

export type RouteInfo = {
  id: string;
  path: string;
  icon: React.ReactNode;
  component: React.FC;
};

export const navigationRoutes: RouteInfo[] = [
  {
    id: 'Map',
    path: '',
    icon: <Icon>map</Icon>,
    component: Map,
  },
  {
    id: 'List',
    path: 'list/',
    icon: <Icon>list</Icon>,
    component: List,
  },
  {
    id: 'Settings',
    path: 'settings/',
    icon: <Icon>settings</Icon>,
    component: Settings,
  },
];

const RootRoutes: React.FC = () => (
  <Routes>
    {navigationRoutes.map((route: RouteInfo, i: number) => (
      <Route
        key={i}
        path={route.path}
        element={
          <Suspense
            fallback={
              <ProgressWrapper>
                <CircularProgress />
              </ProgressWrapper>
            }
          >
            <route.component />
          </Suspense>
        }
      />
    ))}
  </Routes>
);

const Routing: React.FC = () => {
  const user = useRecoilValue(userAtom);
  const location = useLocation();
  const navigate = useNavigate();
  const [initialSelected, setInitialSelected] = useState(false);
  const [savedDataVaildState, setSavedDataValidState] = useState('init');
  const { getProjects } = useGetProjectsAPI();
  const { getEnvironments } = useGetEnvironmentsAPI();
  const { getPlaces } = useGetPlacesAPI();
  const { postAssertProjects, postAssert } = usePostAssertAPI();
  const [environment, setEnvironment] = useRecoilState(environmentAtom);
  const prevEnvironment = usePrevious(environment);
  const setProject = useSetRecoilState(projectAtom);
  const setProjects = useSetRecoilState(projectsAtom);
  const setEnvironments = useSetRecoilState(environmentsAtom);
  const setSelectedVehicleId = useSetRecoilState(selectedVehicleIdAtom);
  const setVehicles = useSetRecoilState(vehiclesAtom);
  const [assert, setAssert] = useRecoilState(assertAtom);
  const prevAssert = usePrevious(assert);
  const setPlaces = useSetRecoilState(placesAtom);
  const rootPath = useRootPath();
  const { getVehicles } = useVehiclesAPI();
  const [environmentChangeLoading, setEnvironmentChangeLoading] =
    useRecoilState(environmentChangeLoadingAtom);
  const setIsAuthError = useSetRecoilState(isAuthErrorAtom);
  const {
    createVehiclesTelemetrySocket,
    closeVehiclesTelemetrySocket,
    createVehiclesMetadataSocket,
    closeVehiclesMetadataSocket,
  } = useVehiclesDataWebSocket();

  const savedEnvironment = useMemo(() => {
    const saved = store.local.get('environment');
    if (saved && saved.project && saved.environment) {
      return saved;
    }
    return null;
  }, []);

  const handleInitialSelect = useCallback(() => {
    setInitialSelected(true);
  }, []);

  useMount(() => {
    // GA
    if (enableGA) {
      ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID);
    }

    AuthenticationManager.userManager.events.addSilentRenewError(() => {
      console.log('silent renew error');
      setIsAuthError(true);
    });

    const { search, pathname, hash } = window.location;
    const hasParamCode = search.includes('code=');
    const hasParamState = search.includes('state=');
    const hasParamScope = search.includes('scope=');
    const savedPathName: string = store.session.get('pathname');
    if (!hasParamCode && !hasParamState && !hasParamScope) {
      store.session.set('pathname', `${pathname}${search}${hash}`);
    } else {
      if (!savedPathName) {
        store.session.set('pathname', '/');
      }
    }
  });

  useEffect(() => {
    // GA
    if (enableGA) {
      ReactGA.send({
        hitType: 'pageview',
        page: `${location.pathname}${location.search}`,
      });
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    const checkSavedEnvironment = async () => {
      // Project & Environment 選択済み
      if (initialSelected) return;
      // User が存在しない場合
      if (!user) return;

      const savedPathName: string = store.session.get('pathname');
      if (!savedPathName) return;
      const savedPathNames = savedPathName.substring(1).split('/');
      const pathProjectId = savedPathNames[0];
      const pathEnvironmentId = savedPathNames[1];

      // LocalStorage or URL に保存された Project & Environment が存在しない場合
      if (!savedEnvironment && !pathProjectId && !pathEnvironmentId) {
        setSavedDataValidState('invalid');
        return;
      }
      let targetProjectId = pathProjectId;
      // ルートパスかつ、保存されている ProjectID が参照できる場合
      if (
        savedPathName === '/' &&
        targetProjectId.length === 0 &&
        savedEnvironment
      ) {
        targetProjectId = savedEnvironment.project.id;
      }
      // Project一覧取得
      const projects = await getProjects();
      setProjects(projects);
      // 保存されているProject IDが取得したProject一覧に含まれるかどうか
      const assertRes = await postAssertProjects(projects);
      // 保存されているProject IDが取得したProject一覧に含まれるかどうか
      const includedProject = assertRes.find(
        (project) => project.id === targetProjectId,
      );
      if (!includedProject) {
        // 含まれていない場合ダイアログ表示
        setSavedDataValidState('invalid');
        return;
      }

      let targetEnvironmentId = pathEnvironmentId;
      // ルートパスかつ、保存されている Environment ID が参照できる場合
      if (
        savedPathName === '/' &&
        (!targetEnvironmentId || targetEnvironmentId.length === 0) &&
        savedEnvironment
      ) {
        targetEnvironmentId = savedEnvironment.environment.environment_id;
      }
      // 保存されたProject IDでEnvironment一覧取得
      const environments = await getEnvironments(targetProjectId);
      setEnvironments(environments);
      // 保存されているEnvironment IDが取得したEnvironment一覧に含まれるかどうか
      const includedEnvironment = environments.find(
        (environment) => environment.environment_id === targetEnvironmentId,
      );
      if (!includedEnvironment) {
        // 含まれていない場合ダイアログ表示
        setSavedDataValidState('invalid');
        return;
      }

      const rootPathName = `/${includedProject.id}/${includedEnvironment.environment_id}/`;
      // 保存されていた情報が問題なければ各値をセット
      if (savedPathName) {
        if (!savedPathName.includes(rootPathName)) {
          navigate(rootPathName);
        } else {
          navigate(savedPathName);
          store.session.remove('pathname');
        }
      } else {
        navigate(rootPathName);
      }
      setProject(includedProject);
      setEnvironment(includedEnvironment);
      setInitialSelected(true);
      setSavedDataValidState('valid');
    };
    checkSavedEnvironment();
  }, [
    user,
    savedEnvironment,
    setProject,
    setProjects,
    setEnvironment,
    setEnvironments,
    initialSelected,
    getProjects,
    getEnvironments,
    postAssertProjects,
    navigate,
  ]);

  useEffect(() => {
    /**
     * スコープアサーション
     */
    const updateScope = async () => {
      setAssert({
        scope: '',
        featureScope: '',
        asserted: false,
      });
      const res = await postAssert();
      if (res) {
        setAssert({
          scope: res.scope,
          featureScope: res.feature_scope,
          asserted: true,
        });
      }
      console.log('complete: post assertion');
    };

    if (
      environment &&
      prevEnvironment?.environment_id !== environment.environment_id
    ) {
      // Websockeを閉じる
      closeVehiclesTelemetrySocket();
      closeVehiclesMetadataSocket();
      setVehicles([]);
      setSelectedVehicleId(null);
      setEnvironmentChangeLoading(true);
      console.group('change environment: api request start');
      updateScope();

      // パスの変更
      const pathNames = window.location.pathname.substring(1).split('/');
      const includeProjectEnvironmentPath = pathNames.splice(2).join('/');
      const params = new URLSearchParams(window.location.search);
      let paramsString =
        params.toString().length > 0 ? `?${params.toString()}` : '';
      if (paramsString.includes('?code=')) {
        paramsString = '';
      }
      const currentPath = `${window.location.pathname}${window.location.search}`;
      const targetPath = `/${environment.project_id}/${environment.environment_id}/${includeProjectEnvironmentPath}${paramsString}`;
      if (currentPath !== targetPath) {
        navigate(targetPath, { replace: true });
      }
    }
  }, [
    environment,
    prevEnvironment,
    setSelectedVehicleId,
    setVehicles,
    navigate,
    closeVehiclesTelemetrySocket,
    closeVehiclesMetadataSocket,
    postAssert,
    setAssert,
    setEnvironmentChangeLoading,
  ]);

  /**
   * 走行環境が変更され、スコープが更新された場合
   */
  useEffect(() => {
    // 停車地点取得
    const updatePlaces = async () => {
      const res = await getPlaces();
      setPlaces(res);
      console.log('complete: get places');
    };

    // 車両情報取得
    const updateVehicles = async () => {
      const res = await getVehicles();
      setVehicles(res);
      await createVehiclesTelemetrySocket();
      await createVehiclesMetadataSocket();
      console.log('complete: get vehicles');
    };

    if (prevAssert?.scope !== assert.scope && assert.scope && assert.asserted) {
      Promise.all([updatePlaces(), updateVehicles()])
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          console.groupEnd();
          setEnvironmentChangeLoading(false);
        });
    }
  }, [
    prevAssert,
    assert,
    getPlaces,
    getVehicles,
    setPlaces,
    setVehicles,
    createVehiclesTelemetrySocket,
    createVehiclesMetadataSocket,
    setEnvironmentChangeLoading,
  ]);

  if (oidcErrorDescription()) {
    return (
      <Box
        display="flex"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        {oidcErrorDescription()}
      </Box>
    );
  }

  if (!initialSelected) {
    // Project & Environment が選択されていない場合

    if (user && savedDataVaildState === 'invalid') {
      // Userが存在 && 保存されたProject & Environmentが存在しない or 不正な場合ダイアログ表示
      return (
        <ProjectEnvironmentDialog
          open
          onInitialSelected={handleInitialSelect}
        />
      );
    }

    return (
      <>
        <ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>
        <AuthErrorDialog />
        <Routes>
          <Route path="*" element={<SignInCallback />} />
        </Routes>
      </>
    );
  }

  if (environmentChangeLoading) {
    return (
      <>
        <ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>
        <AuthErrorDialog />
      </>
    );
  }

  return (
    <Layout>
      <Routes>
        <Route path={`${rootPath}*`} element={<RootRoutes />} />
      </Routes>
      <AuthErrorDialog />
    </Layout>
  );
};

export default Routing;

const ProgressWrapper = styled(Paper)`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
`;
