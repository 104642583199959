import { isAuthErrorAtom } from '@/data/auth/user/states';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

const AuthErrorDialog: React.FC = () => {
  const isAuthError = useRecoilValue(isAuthErrorAtom);

  /**
   * リロードボタンクリック
   */
  const handleClickReloadButton = useCallback(() => {
    window.location.reload();
  }, []);

  if (!isAuthError) return null;

  return (
    <Dialog open>
      <DialogTitle>認可エラー</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <DialogContentText>
          認可トークン取得時にエラーが発生しました
          <br />
          ページをリロードしてください
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClickReloadButton}>
          再読み込み
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthErrorDialog;
