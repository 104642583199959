export const FEATURE_SCOPES = {
  VideoStreaming: 'VideoStreaming',
  Report: 'Report',
  SetScheduleExpiration: 'SetScheduleExpiration',
  BasicSchedule: 'BasicSchedule',
  LoopSchedule: 'LoopSchedule',
  ScheduleHistory: 'ScheduleHistory',
  MaintenanceSchedule: 'MaintenanceSchedule',
  VoiceCall: 'VoiceCall',
} as const;

/**
 * <project_id>:environment:<environment_id>=<operation> のスコープ
 */
export const SCOPES = {
  // 走行環境
  Describe: 'Describe',
  Update: 'Update',
  // 車両
  DescribeVehicleMetadata: 'DescribeVehicleMetadata',
  DescribeVehicleTelemetry: 'DescribeVehicleTelemetry',
  CreateVehicle: 'CreateVehicle',
  DeleteVehicle: 'DeleteVehicle',
  ResumeVehicle: 'ResumeVehicle',
  StartVehicle: 'StartVehicle',
  SuspendVehicle: 'SuspendVehicle',
  UpdateVehicleName: 'UpdateVehicleName',
  UpdateVehicleCanStart: 'UpdateVehicleCanStart',
  UpdateVehicleAcceptableOrder: 'UpdateVehicleAcceptableOrder',
  UpdateVehicleStuckAlarmSnooze: 'UpdateVehicleStuckAlarmSnooze',
  UpdateVehicleEnvironment: 'UpdateVehicleEnvironment',
  UpdateVehicleDescription: 'UpdateVehicleDescription',
  UpdateVehicleInitialPoint: 'UpdateVehicleInitialPoint',
  UpdateVehicleSoftware: 'UpdateVehicleSoftware',
  UpdateVehicleMap: 'UpdateVehicleMap',
  // スケジュール
  CancelSchedule: 'CancelSchedule',
  CompleteScheduleTask: 'CompleteScheduleTask',
  CreateBasicSchedule: 'CreateBasicSchedule',
  CreateLoopSchedule: 'CreateLoopSchedule',
  CreateMaintenanceSchedule: 'CreateMaintenanceSchedule',
  CreateScheduleWithExpiration: 'CreateScheduleWithExpiration',
  DescribeSchedule: 'DescribeSchedule',
  ResetSchedule: 'ResetSchedule',
  // 走行環境オプション
  DescribeWebAutoAppOption: 'DescribeWebAutoAppOption',
  UpdateWebAutoAppOption: 'UpdateWebAutoAppOption',
  // 地図
  DescribePlace: 'DescribePlace',
  DescribeRoute: 'DescribeRoute',
  SearchRoute: 'SearchRoute',
  ChangeAreaMap: 'ChangeAreaMap',
  // データ基盤
  DescribeProbe: 'DescribeProbe',
  DescribeBag: 'DescribeBag',
  DescribeReport: 'DescribeReport',
  DescribeVehicleError: 'DescribeVehicleError',
  CreateDrivingNote: 'CreateDrivingNote',
  UpdateDrivingNote: 'UpdateDrivingNote',
  DescribeDrivingNote: 'DescribeDrivingNote',
  DeleteDrivingNote: 'DeleteDrivingNote',
  // Media API
  DescribeMediaStatus: 'DescribeMediaStatus',
  ReceiveMediaStream: 'ReceiveMediaStream',
} as const;
