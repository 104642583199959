import { createTheme, type ThemeOptions } from '@mui/material/styles';
import { type Shadows } from '@mui/material/styles/shadows';

const createShadow = (px: number): string => `0 0 ${px}px 0 rgba(53,64,82,.07)`;

export const shadows: Shadows = [
  'none',
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
  createShadow(14),
];

export const appTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#06579e',
    },
  },
  typography: {
    h5: {
      fontSize: '1.2rem',
    },
    h6: {
      fontSize: '1rem',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1700,
    },
  },
  shadows,
};

export const theme = createTheme({ ...appTheme, spacing: 8 });
