import type {
  ROSVersion,
  Vehicle,
  VehicleCatalog,
  VehicleCreationStatus,
  VehicleFirmwareType,
  VehicleTelemetry,
  VehicleTelemetryStatus,
  VehicleVisualizationStatus,
} from './types';
import { ROS_VERSION, VEHICLE_CATEGORIES, VEHICLE_FIRMWARE } from './constants';

/**
 * telemetry.status が disconnected かどうかを返す
 * @param vehicle
 * @return {boolean}
 */
export const isDisconnected = (vehicle: Vehicle): boolean =>
  vehicle.telemetry.status === 'disconnected';

/**
 * telemetry.status が connected かどうかを返す
 * @param vehicle
 */
export const isConnected = (vehicle: Vehicle): boolean =>
  vehicle.telemetry.status === 'connected';

/**
 * telemetry
 * @param vehicle
 */
export const isShutdown = (vehicle: Vehicle): boolean =>
  vehicle.telemetry.status === 'shutdown';

/**
 * エラーかどうかを返す
 */
export const isError = (vehicle: Vehicle): boolean =>
  vehicle.telemetry.status === 'abnormal';

/**
 * 一時停止中かどうかを返す
 */
export const isSuspending = (vehicle: Vehicle): boolean =>
  vehicle.telemetry.status === 'suspending';

/**
 * 一時停止線で停止中かどうかを返す
 */
export const isPauseForStopLine = (vehicle: Vehicle): boolean =>
  vehicle.telemetry.status === 'pausing_for_stop_line';

/**
 * 障害物検知中かどうかを返す
 */
export const isObstracleDetectionStop = (vehicle: Vehicle): boolean =>
  vehicle.telemetry.status === 'pausing_for_obstacle';

/**
 * スケジュール登録が可能どうかを返す
 * disconnected ではない && shutdown ではない && 配車受付が none ではない
 */
export const isScheduleRegister = (vehicle: Vehicle): boolean =>
  !isDisconnected(vehicle) &&
  !isShutdown(vehicle) &&
  vehicle.acceptable_order !== 'none';

/**
 * カタログ情報を持つか返す
 * @return {Boolean}
 */
export const hasCatalog = (catalog: VehicleCatalog): boolean =>
  !!catalog.catalog_id && catalog.catalog_id.length > 0;
/**
 * T4BetaをIVに変更して返す
 */
export const firmwareLabel = (
  type: VehicleFirmwareType,
): 'Autoware.IV' | VehicleFirmwareType => {
  if (type === VEHICLE_FIRMWARE.T4Beta) return 'Autoware.IV';
  return type;
};

/**
 * ROS Versionを数値にして返す
 */
export const convertROSVersion = (ros: ROSVersion): number => {
  if (ros === ROS_VERSION.ROS1) return 1;
  if (ros === ROS_VERSION.ROS2) return 2;
  return 1;
};

export const noCategoryVehicle = (vehicle: Vehicle): boolean =>
  vehicle &&
  vehicle.category === VEHICLE_CATEGORIES[2] &&
  vehicle.firmware_type === VEHICLE_FIRMWARE.T4Beta;

export const noAppFederationVehicle = (vehicle: Vehicle): boolean =>
  vehicle && vehicle.enable_in_vehicle_app_federation === null;

export const isActualVehicle = (vehicle: Vehicle): boolean =>
  vehicle &&
  vehicle.firmware_type === VEHICLE_FIRMWARE.T4Beta &&
  vehicle.category === VEHICLE_CATEGORIES[0];

export const isFSimVehicle = (vehicle: Vehicle): boolean =>
  vehicle && vehicle.firmware_type === VEHICLE_FIRMWARE.Simulator;

export const isPSimVehicle = (vehicle: Vehicle): boolean =>
  vehicle &&
  vehicle.firmware_type === VEHICLE_FIRMWARE.T4Beta &&
  vehicle.category === VEHICLE_CATEGORIES[1];

/**
 * 文字列をBoolean型に変換
 */
export const toBoolean = (data: string): boolean =>
  data.toLowerCase() === 'true';

/**
 * @param battery
 * @returns null or battery
 */
export const batteryLabel = (battery: number | null): number | null => {
  if (!battery) return null;
  return Math.round(battery * 100) / 100;
};

/**
 * トップページの車両ステータスによる並び順
 */
export const vehicleListSortOrder: VehicleTelemetryStatus[] = [
  'ready',
  'abnormal',
  'task_acceptable',
  'initializing',
  'suspending',
  'stopping',
  'driving',
  'connected',
  'disconnected',
];

/**
 *
 * @param value
 */
export const fixedSpeed = (value: number | undefined): string =>
  value && value !== 0 && !Number.isNaN(value)
    ? Math.abs(Math.round(Number(value * 3.6))).toFixed()
    : '- ';

/**
 * Visualization が有効化されているかどうか
 */
export const isActiveVisualization = (
  status: VehicleVisualizationStatus,
): boolean => status && status === 'active';

/**
 * EmergencyStatusの書き換え
 */
export const overrideTelemetryEmergencyStatus = (
  telemetry: VehicleTelemetry,
) => {
  if (!telemetry) return;
  telemetry.is_emergency = false;
  telemetry.is_emergency_holding = false;
};

/**
 * AD API のバージョンを文字列から数字に変換
 */
export const convertADAPIVersionToNumber = (
  version: string | null | undefined,
) => (version ? Number(version.replaceAll('.', '')) : null);

/**
 * AD API のバージョン毎の機能可否
 */
export const isAllowGoalModification = (version: string | null | undefined) => {
  const convertedVersion = convertADAPIVersionToNumber(version);
  return !!convertedVersion && convertedVersion >= 110;
};

/**
 * 車両の作成ステータスを見て設定が変更可能かどうかを返す
 * @return {Boolean}
 */
export const isSettingsChangeableByCreationStatus = (
  status: VehicleCreationStatus,
) => status === 'ready';
