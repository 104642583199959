import type { Project } from '@/data/auth/project/types';
import { useCallback, useState } from 'react';
import { Failure, Success, type APIState, type Result } from '../types';
import { useAuthAPI } from '../hooks';
import type { AxiosError, AxiosResponse } from 'axios';
import { useNotification } from '@/data/notification/hooks';

type ProjectsAPIResponse = {
  projects: Project[];
  next_page_token?: string;
};

/**
 * プロジェクト一覧取得
 */
export const useGetProjectsAPI = (): {
  state: APIState;
  getProjects: () => Promise<Project[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const authAPI = useAuthAPI();

  /**
   * プロジェクト一覧取得
   */
  const request = useCallback(
    async (
      params: URLSearchParams,
    ): Promise<Result<ProjectsAPIResponse, AxiosResponse>> => {
      try {
        const res = await authAPI.get(`/projects?${params.toString()}`);
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [authAPI],
  );

  const getProjects = useCallback(async (): Promise<Project[]> => {
    setState('loading');

    // 分割リクエスト
    let count = 0;
    let projects: Project[] = [];
    const params = new URLSearchParams();
    params.append('page_size', '100');

    const splitRequest = async (): Promise<'error' | 'success'> => {
      const res = await request(params);
      // 取得失敗時
      if (!res || res.isFailure()) {
        if (count === 0) {
          // 初回のリクエストに失敗した場合
          if (res.value?.status !== 404) {
            notifyError({
              message: `プロジェクト一覧の取得に失敗しました: ${
                res.value?.data?.error_description ?? '原因不明'
              }`,
            });
          }
          return 'error';
        } else {
          // 初回移行の途中のリクエスに失敗した場合通知だけ出す
          if (res.value?.status !== 404) {
            notifyError({
              message: `プロジェクト一覧の取得に失敗しました: ${
                res.value?.data?.error_description ?? '原因不明'
              }`,
            });
          }
        }
      }
      // 取得成功時
      if (res && res.isSuccess()) {
        count += 1;
        projects = projects.concat(res.value.projects);
        if (res.value.next_page_token && res.value.next_page_token.length > 0) {
          // レスポンスに next_page_token がある場合は次のリクエストを行う
          if (params) {
            params.set('page_token', res.value.next_page_token);
          }
          await splitRequest();
        }
      }
      //
      return 'success';
    };

    const splitRequestRes = await splitRequest();
    if (splitRequestRes === 'error') {
      setState('hasError');
      return [];
    }

    setState('hasValue');
    projects.sort((a, b) => (a.display_name < b.display_name ? -1 : 1));
    return projects;
  }, [request, notifyError]);

  return { state, getProjects };
};

/**
 * 指定IDのプロジェクト情報取得
 */
export const useGetProjectAPI = (): {
  state: APIState;
  getProject: (projectId: string) => Promise<Project | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const authAPI = useAuthAPI();

  /**
   * プロジェクト情報取得
   */
  const request = useCallback(
    async (projectId: string): Promise<Result<Project, AxiosResponse>> => {
      try {
        const res = await authAPI.get(`/projects/${projectId}`);
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [authAPI],
  );

  const getProject = useCallback(
    async (projectId: string): Promise<Project | null> => {
      setState('loading');
      const res = await request(projectId);
      if (!res || res.isFailure()) {
        if (res.value?.status !== 404) {
          notifyError({
            message: `プロジェクトの取得に失敗しました: ${
              res.value?.data?.error_description ?? '原因不明'
            }`,
          });
        }
        setState('hasError');
        return null;
      }
      setState('hasValue');
      return res.value;
    },
    [request, notifyError],
  );

  return { state, getProject };
};
